<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">已提交的任务</h2>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="taskName" label="任务名称" align="center">
          </el-table-column>
          <el-table-column prop="assignees" label="分配人" align="center">
          </el-table-column>
          <el-table-column prop="executor" label="执行人" align="center">
          </el-table-column>
          <el-table-column prop="statusName" label="任务状态" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template style="display: flex" slot-scope="scope">
              <el-button v-if="scope.row.passButton=='1'" size="mini" type="primary" @click="updateStatus(scope.row)">通过</el-button>
              <el-button v-if="scope.row.overruledButton=='1'" size="mini" type="primary" @click="revStat(scope.row)">驳回</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>

        <el-dialog title="驳回原因" :visible.sync="revsuggdialogVisible" width="500px" :before-close="revsuggdialogVisibleClose">
          <div class="revSty">
            <el-input v-model="finReje" placeholder="请填写驳回原因"></el-input>
            <el-button type="primary" style="margin-top: 20px" @click="revmsgStatus()">提交</el-button>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      finReje:'',
      revsuggdialogVisible:false,
      reayName: "",
      // 学组管理的分页
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      taskId:"",
      revId:""
    };
  },
  created() {
    this.getTaskList();
  },
  methods: {
    revsuggdialogVisibleClose(){
      this.revsuggdialogVisible=false
      this.revId=''
    },
    revStat(row){
      // console.log(row)
      this.revId=row.sutId
      this.revsuggdialogVisible=true
    },
    async revmsgStatus(){
      // console.log(row)
      let data = _qs.stringify({
        id:this.revId,
        status:3,//点击开始任务按钮 1 /提交按钮 2 /驳回按钮 3 /通过按钮 4
        msg:this.finReje,//修改意见 驳回时必传
      });
      let { data: res } = await this.$axios.updateStatus(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.revsuggdialogVisibleClose()
        this.getTaskList();
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 通过
    async updateStatus(row){
      // console.log(row)
      let data = _qs.stringify({
        id:row.sutId,
        status:4,//点击开始任务按钮 1 /提交按钮 2 /驳回按钮 3 /通过按钮 4
        msg:'',//修改意见 驳回时必传
      });
      let { data: res } = await this.$axios.updateStatus(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getTaskList();
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    change(e) {
      this.$forceUpdate(e);
    },
    // 学组管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.getTaskList();
    },
    assPer(row){
      // console.log(row)
      this.taskId=row.taskId
      this.taskLoad()
    },
    async getTaskList() {
      let data = _qs.stringify({
        conferenceId:window.sessionStorage.getItem('conferenceId'),//会议id
        status:2,//状态 0待处理/1进行中/2已提交/3已完成
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
      });
      let { data: res } = await this.$axios.commissionList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.revSty{
  text-align: center;
  .el-button{
    width:150px;
    margin-top: 20px;
  }
}

</style>
